import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const [author] = get(this, 'props.data.allContentfulHomePage.edges')

    return (
      <Layout location={this.props.location} >
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle} />
          <Hero data={author.node} />
          <div className="wrapper">
            <h2 className="section-headline">{author.node.headline}</h2>
            <ul className="article-list">
              {posts.map(({ node }) => {
                return (
                  <li key={node.slug}>
                    <ArticlePreview article={node} />
                  </li>
                )
              })}
            </ul>
          </div>
          <h2 className="section-headline" id="contact">Contactez-nous</h2>
          <div class="contact-more">
            <a href="https://goo.gl/maps/Q7h8ZqRaFrJmCKHBA" target="blank_">Notre adresse</a>
            <a href="https://fr-fr.facebook.com/leszellesdejagat/" target="blank_">Notre page Facebook</a>
            <a href="tel:+33678097630">Appelez-nous</a>
          </div>
          <form
            class="form"
            name="Contact"
            method="post"
            action="/success"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            netlify
          >
            <input type="hidden" name="bot-field" />
            <div>
              <input class="input" htmlFor="name" placeholder="Nom *" type="text" name="name" id="name" required />
            </div>
            <div>
              <input class="input" htmlFor="email" placeholder="Email *" type="text" name="email" id="email" required />
            </div>
            <div>
              <textarea class="textarea" htmlFor="message" placeholder="Message *" name="message" id="message" rows="6" required />
            </div>
            <div>
              <input type="submit" value="Envoyer" id="submitButton" />
            </div>
          </form>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "DD - MM - YYYY")
          description {
            childMarkdownRemark {
              html
            }
          }
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: NO_CHANGE) {
             ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
    allContentfulHomePage(filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          headline
          heroImage: image {
            fluid(
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
