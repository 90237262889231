import React from 'react'
import Img from 'gatsby-image'

import styles from './hero.module.css'
import { Link } from 'gatsby';

export default ({ data }) => (
  <div className={styles.hero}>
    <Img className={data.name ? styles.heroImage : styles.heroImageWithoutButton} alt={data.name} fluid={data.heroImage.fluid} />
    <div className={styles.heroDetails}>
      <h3 className={styles.heroHeadline}>
        {data.title}
      </h3>
      {data.shortBio ? (
        <p>{data.shortBio.shortBio}</p>
      ) : null}
      {data.name ? (
      <Link to={`/dons/`} style={{
        textDecoration: 'none',
        marginTop: '2em'
      }}>
          <div className={styles.act}>
            {data.name}
          </div>
      </Link>
      ) : null}
    </div>
  </div>
)
